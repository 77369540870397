import React, {useEffect, useState} from "react";
import "../../../../../sass/client/global/modals.scss";
import moment from "moment";


export default function ChangeBillingInformationModal({modalVisible, closeModal, registration, handleSubmitted}) {

    const [invoiceCompany, setInvoiceCompany] = useState(registration.invoice_profile ? registration.invoice_profile.invoice_company : '');
    const [invoiceStreet, setInvoiceStreet] = useState(registration.invoice_profile ? registration.invoice_profile.invoice_street : '');
    const [invoiceCity, setInvoiceCity] = useState(registration.invoice_profile ? registration.invoice_profile.invoice_city : '');
    const [invoicePostal, setInvoicePostal] = useState(registration.invoice_profile ? registration.invoice_profile.invoice_postal : '');
    const [invoiceCountryId, setInvoiceCountryId] = useState(registration.invoice_profile ? registration.invoice_profile.country.id : '');
    const [invoiceId, setInvoiceId] = useState(registration.invoice_profile ? (registration.invoice_profile.invoice_id !== null ? registration.invoice_profile.invoice_id : '') : '');
    const [invoiceTaxId, setInvoiceTaxId] = useState(registration.invoice_profile ? (registration.invoice_profile.invoice_tax_id !== null ? registration.invoice_profile.invoice_tax_id : '') : '');
    const [invoiceVatId, setInvoiceVatId] = useState(registration.invoice_profile ? (registration.invoice_profile.invoice_vat_id !== null ? registration.invoice_profile.invoice_vat_id : '') : '');

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState(null);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        axios.get(`/api/countries`)
            .then((response) => {
                setCountries(response.data.countries);
            })
    }, []);

    const handleSubmit = () => {
        setSending(true);

        const data = new FormData;
        data.append('invoice_company', invoiceCompany);
        data.append('invoice_street', invoiceStreet);
        data.append('invoice_city', invoiceCity);
        data.append('invoice_postal', invoicePostal);
        data.append('invoice_country_id', invoiceCountryId);
        data.append('invoice_id', invoiceId);
        data.append('invoice_tax_id', invoiceTaxId);
        data.append('invoice_vat_id', invoiceVatId);

        axios.post(`/api/${registration.id}/change-billing-information`, data)
            .then(response => {
                setSending(false);
                handleSubmitted();
                closeModal();
                // Uděláme hard refresh, kvůli aktualizaci údajů v blade
                // window.location.reload();
            })
            .catch(error => {
                setSending(false);
                console.error(error);
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            })
    }

    return (
        <>
            {modalVisible === registration.id && (
                <>
                    {registration.paid === false || registration.paid === null || (registration.paid_at !== null && moment(registration.paid_at).isSame(new Date(), 'month')) ?
                        (
                            <>
                                <div className="backdrop"></div>
                                <div className="modal modal--m">
                                    <div className="modal__header pb--0">
                                        <h4 className="mb--s"><i
                                            className="fad fa-file-invoice mr--xs text--primary"></i> Zmeniť fakturačné
                                            údaje
                                        </h4>

                                        <button className={"modal__close"}
                                                disabled={sending}
                                                onClick={closeModal}
                                        >
                                            <i className="fal fa-times"></i>
                                        </button>
                                    </div>

                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}>
                                        <div className="modal__body">
                                            <div className="modal__full gray">
                                                <div className="input-container">
                                                    <label className={'input__label'}>Obchodné meno alebo názov</label>
                                                    <input
                                                        type="text"
                                                        className={`input__item ${errors && errors['invoice_company'] ? 'error' : ''}`}
                                                        value={invoiceCompany}
                                                        onChange={(e) => {
                                                            setInvoiceCompany(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="d--flex">
                                                    <div className="input-container mr--s">
                                                        <label className={'input__label'}>IČO</label>
                                                        <input
                                                            type="text"
                                                            className={`input__item ${errors && errors['invoice_id'] ? 'error' : ''}`}
                                                            value={invoiceId}
                                                            onChange={(e) => {
                                                                setInvoiceId(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-container">
                                                        <label className={'input__label'}>DIČ</label>
                                                        <input
                                                            type="text"
                                                            className={`input__item ${errors && errors['invoice_tax_id'] ? 'error' : ''}`}
                                                            value={invoiceTaxId}
                                                            onChange={(e) => {
                                                                setInvoiceTaxId(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="input-container">
                                                    <label className={'input__label'}>IČ DPH</label>
                                                    <input
                                                        type="text"
                                                        className={`input__item ${errors && errors['invoice_vat_id'] ? 'error' : ''}`}
                                                        value={invoiceVatId}
                                                        onChange={(e) => {
                                                            setInvoiceVatId(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="input-container">
                                                    <label className={'input__label'}>Ulica</label>
                                                    <input
                                                        type="text"
                                                        className={`input__item ${errors && errors['invoice_street'] ? 'error' : ''}`}
                                                        value={invoiceStreet}
                                                        onChange={(e) => {
                                                            setInvoiceStreet(e.target.value);
                                                        }}
                                                    />
                                                </div>

                                                <div className="d--flex">
                                                    <div className="input-container mr--s">
                                                        <label className={'input__label'}>Mesto</label>
                                                        <input
                                                            type="text"
                                                            className={`input__item ${errors && errors['invoice_city'] ? 'error' : ''}`}
                                                            value={invoiceCity}
                                                            onChange={(e) => {
                                                                setInvoiceCity(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-container">
                                                        <label className={'input__label'}>PSČ</label>
                                                        <input
                                                            type="text"
                                                            className={`input__item ${errors && errors['invoice_postal'] ? 'error' : ''}`}
                                                            value={invoicePostal}
                                                            onChange={(e) => {
                                                                setInvoicePostal(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="input-container">
                                                    <label className={'input__label'}>Štát</label>
                                                    <div
                                                        className={`custom-select ${errors && errors['invoice_country_id'] ? 'error' : ''}`}>

                                                        <select
                                                            value={invoiceCountryId}
                                                            onChange={(e) => {
                                                                setInvoiceCountryId(e.target.value);
                                                            }}
                                                        >
                                                            {
                                                                countries.map((country, i) => (
                                                                    <React.Fragment key={country.id}>
                                                                        <option
                                                                            value={country.id}>{country.name_local}</option>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal__footer">

                                            <button className={"btn btn--text btn--xs mr--xs"}
                                                    disabled={sending}
                                                    onClick={closeModal}
                                            >
                                                Zrušiť
                                            </button>

                                            <button type="submit"
                                                    disabled={sending}
                                                    className={"btn btn--primary btn--xs btn--shadow"}
                                            >
                                                {sending ? 'Spracovávám...' : 'Zmeniť'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="backdrop"></div>
                                <div className="modal modal--m">
                                    <div className="modal__header pb--0">
                                        <h4 className="mb--s"><i
                                            className="fad fa-file-invoice mr--xs text--primary"></i> Zmeniť fakturačné
                                            údaje
                                        </h4>
                                    </div>

                                    <div className="modal__body">
                                        <div className="modal__full pt--0">
                                            <p>Fakturačné údaje už nemožno zmeniť.</p>
                                        </div>
                                    </div>

                                    <div className="modal__footer">

                                        <button className={"btn btn--text btn--xs mr--xs"}
                                                disabled={sending}
                                                onClick={closeModal}
                                        >
                                            Zrušiť
                                        </button>

                                    </div>
                                </div>
                            </>
                        )}
                </>
            )
            }
        </>
    )
}
