import ReactDOM from "react-dom";
import OrderHistoryIndex from "./App/Modules/OrderHistory/OrderHistoryIndex";
import React from "react";
import DeleteAccountButton from "./App/Modules/DeleteAccount/DeleteAccountButton";

// Require bootstrap
require('./bootstrap');


// Load React
if (document.getElementById('order-history')) {
    ReactDOM.render(
        <OrderHistoryIndex />,
        document.getElementById('order-history')
    );
}

if (document.getElementById('delete-account-button')) {
    ReactDOM.render(
        <DeleteAccountButton />,
        document.getElementById('delete-account-button')
    );
}
