import React, {useEffect, useState} from 'react';
import DeleteAccountRequestModal from "./DeleteAccountRequestModal";
import moment from "moment";


export default function DeleteAccountButton() {

    const [deleteModalVisible, setDeleteModalVisible] = useState(null);
    const [loading, setLoading]                       = useState(false);
    const [successFlashes, setSuccessFlashes]         = useState([]);
    const [requestTypes, setRequestTypes]             = useState([]);
    const [deleteRequest, setDeleteRequest]           = useState(null);
    const [timestamp, setTimestamp]                   = useState(new Date());


    useEffect(() => {
        (async () => {
            setLoading(true);
            const deleteRequest = await axios.get(`/api/delete-request`);
            const requestTypes  = await axios.get(`/api/support-request-types`);
            setLoading(false);
            setRequestTypes(requestTypes.data.types);
            setDeleteRequest(deleteRequest.data);
            console.log(deleteRequest.data);
        })()
    }, [timestamp])

    return (
        <div>
            {successFlashes.length > 0 && (
                successFlashes.map(flash => (
                    <div id="flashes" className="alert alert--success" role="alert">
                        {flash}
                    </div>
                ))
            )}
            {
                loading ? null : (
                    deleteRequest ? (
                        <p>Žiadosť o zrušenie účtu <br/> podaná {moment(deleteRequest.created_at).format('DD.MM.YYYY')}
                        </p>
                    ) : (
                        <>
                            <button className="btn btn--xs btn--danger-inv" onClick={() => {
                                setDeleteModalVisible(true)
                            }}>
                                Zrušiť účet
                            </button>


                            <DeleteAccountRequestModal
                                modalVisible={deleteModalVisible}
                                closeModal={() => {
                                    setDeleteModalVisible(null)
                                }}
                                requestType={'DELETE_ACCOUNT_REQUEST' in requestTypes && requestTypes['DELETE_ACCOUNT_REQUEST']}
                                handleSubmitted={() => {
                                    setTimestamp(new Date());
                                    setSuccessFlashes([...successFlashes, 'Požiadavka o zrušenie účtu bola odoslaná'])
                                }}
                            />

                        </>))
            }
        </div>)
}
