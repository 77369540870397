import React, {useEffect, useState} from 'react';
import moment from "moment";
import GeneralRequestModal from "./GeneralRequestModal";
import CancelOrderModal from "./CancelOrderModal";
import ChangeBillingInformationModal from "./ChangeBillingInformationModal";


export default function OrderHistoryIndex() {

    const [generalRequestModalVisible, setGeneralRequestModalVisible] = useState(null);
    const [cancelOrderModalVisible, setCancelOrderModalVisible] = useState(null);
    const [changeBillingInformationModalVisible, setChangeBillingInformationModalVisible] = useState(null);
    const [orderHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [requestTypes, setRequestTypes] = useState([]);
    const [timestamp, setTimestamp] = useState(new Date());
    const [successFlashes, setSuccessFlashes] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const orderHistory = await axios.get(`/api/ucet/historia-objednavok`);
            const requestTypes = await axios.get(`/api/support-request-types`);

            setLoading(false);
            setOrderHistory(orderHistory.data.registrations);
            setRequestTypes(requestTypes.data.types);
        })()
    }, [timestamp])

    const canChangeBillingInformation = (order) => {
        if (order.paid_at !== null) {
            if (moment(order.paid_at).isSame(new Date(), 'month')) {
                return true;
            }
        }

        if (order.paid_at === null && order.conference.dates.length > 0 && moment(order.conference.dates[0].date).isAfter(new Date())) {
            return true;
        }

        return false;
    }

    const payWithCard = (orderId) => {
        axios.get(`/api/${orderId}/cardpay`)
            .then(response => window.location.replace(response.data))
    }

    return (
        <div>
            {successFlashes.length > 0 && (
                successFlashes.map(flash => (
                    <div id="flashes" className="alert alert--success" role="alert">
                        {flash}
                    </div>
                ))
            )}

            {
                loading ? (
                    <p>
                        <i className="fas fa-spinner fa-spin mr--xxs"/>
                        Načítavam Vaše objednávky...
                    </p>
                ) : (
                    orderHistory.length !== 0 ? (
                        orderHistory.map(order => (
                            <div key={order.id} className="content-block col--2 mb--m">

                                <GeneralRequestModal modalVisible={generalRequestModalVisible}
                                                     closeModal={() => {
                                                         setGeneralRequestModalVisible(null)
                                                     }}
                                                     registration={order}
                                                     requestType={'GENERAL_REQUEST' in requestTypes && requestTypes['GENERAL_REQUEST']}
                                                     handleSubmitted={() => {
                                                         setSuccessFlashes([...successFlashes, 'Vaša požiadavka bola odoslaná'])
                                                     }}
                                />
                                {order.is_registration_owner && (
                                    <>
                                        <CancelOrderModal modalVisible={cancelOrderModalVisible}
                                                          closeModal={() => {
                                                              setCancelOrderModalVisible(null)
                                                          }}
                                                          registration={order}
                                                          requestType={'REGISTRATION_DELETE_REQUEST' in requestTypes && requestTypes['REGISTRATION_DELETE_REQUEST']}
                                                          handleSubmitted={() => {
                                                              setTimestamp(Date.now())
                                                              setSuccessFlashes([...successFlashes, 'Vaša požiadavka bola odoslaná'])
                                                          }}
                                        />
                                        <ChangeBillingInformationModal
                                            modalVisible={changeBillingInformationModalVisible}
                                            closeModal={() => {
                                                setChangeBillingInformationModalVisible(null)
                                            }}
                                            registration={order}
                                            handleSubmitted={() => {
                                                setTimestamp(Date.now())
                                                setSuccessFlashes([...successFlashes, 'Fakturačné údaje boli úspešne zmenené. Stiahnite si novú predfaktúru']);
                                            }}
                                        />
                                    </>
                                )}

                                <div className="block__column">
                                    <div className="block__heading">
                                        <h4>{order.conference.name ?? ''}</h4>
                                        <span
                                            className={"text--xs"}>Vytvorená: <strong>{moment(order.created_at).format('DD.MM.YYYY')}</strong></span>
                                        {order.participants.length !== 0 &&
                                        <>
                                            {/*{!order.is_registration_owner && (*/}
                                            {/*    order.participants.map(participant => {*/}
                                            {/*        if (order.user_id === participant.user_id) {*/}
                                            {/*            return (*/}
                                            {/*                <h5 key={participant.id}>{participant.name} {participant.surname} - <i>{participant.admission.name} {participant.discount_id ? participant.discount.price : participant.admission.price}€</i>*/}
                                            {/*                </h5>*/}
                                            {/*            )*/}
                                            {/*        }*/}
                                            {/*    })*/}
                                            {/*)}*/}
                                            {/*{order.is_registration_owner && (*/}
                                            {
                                                order.participants.map(participant => (
                                                    <h5 key={participant.id}>
                                                        {participant.name} {participant.surname}
                                                        {
                                                            participant.admission && (
                                                                <i> - {participant.admission.name} {participant.discount_id ? participant.discount.price : participant.admission.price}€</i>
                                                            )
                                                        }
                                                    </h5>
                                                ))
                                            }
                                            {/*)}*/}
                                        </>
                                        }
                                    </div>
                                    <div className="block__details">
                                        <div className="detail__item mr--m">
                                            <img src={`${window.location.origin}/img/conference/calendar-gray.svg`}
                                                 className="mr--xs" alt=""/>
                                            {
                                                order.conference.dates.length > 0 && (
                                                    order.conference.printable_short_date
                                                )
                                            }
                                        </div>

                                        <div className="detail__item">
                                            <img src={`${window.location.origin}/img/conference/marker-gray.svg`}
                                                 className="mr--xs" alt=""/>
                                            {order.conference.location?.city ?? ''}, {order.conference.location?.hotel_name ?? ''}
                                        </div>
                                    </div>
                                    <div className="d--flex mt--m">
                                        {order.is_registration_owner && !order.cancellation_pending && order.can_change_billing_information && canChangeBillingInformation(order) && (
                                            <button
                                                className="btn btn--text gray btn--xs p--0 mr--s"
                                                onClick={() => {
                                                    setChangeBillingInformationModalVisible(order.id);
                                                }}
                                            >
                                                <i className="fas fa-file-invoice mr--xxs"></i>Zmeniť fakturačné údaje
                                            </button>
                                        )}

                                        <button
                                            className="btn btn--text gray btn--xs p--0 mr--s"
                                            onClick={() => {
                                                setGeneralRequestModalVisible(order.id);
                                            }}
                                        >
                                            <i className="fas fa-comments mr--xxs"></i>Mám požiadavku
                                        </button>

                                        {order.is_registration_owner && !order.cancellation_pending && order.can_request_cancellation && order.conference.dates.length > 0 && moment(order.conference.dates[0].date).isAfter(moment(), 'day') && (
                                            <button
                                                className="btn btn--text danger btn--xs p--0 mr--s"
                                                onClick={() => {
                                                    setCancelOrderModalVisible(order.id);
                                                }}
                                            >
                                                <i
                                                    className="fas fa-times mr--xxs"></i>Zrušiť objednávku
                                            </button>
                                        )}

                                        {order.is_registration_owner && order.cancellation_pending && !order.is_cancellation_resolved && (
                                            <div className="label label--danger-inv label--m">Žiadosť o zrušenie
                                                objednávky sa spracováva</div>
                                        )}
                                        {order.cancelled && (
                                            <div className="label label--danger label--m">Objednávka bola zmenená alebo
                                                zrušená</div>
                                        )}
                                        {!order.cancelled && order.is_registration_owner && !order.cancellation_pending && !order.can_request_cancellation && (
                                            <div className="label label--danger label--m">Žiadosť o zrušenie objednávky
                                                bola zamietnutá</div>
                                        )}

                                        {/* todo: tu je platba kartou */}
                                        {/*{order.is_registration_owner && !order.cancellation_pending && order.can_request_cancellation && !order.paid && (*/}
                                        {/*    <button onClick={() => {*/}
                                        {/*        payWithCard(order.id);*/}
                                        {/*    }}>Zaplatit kartou</button>*/}
                                        {/*)}*/}

                                    </div>
                                </div>

                                <div className="block__column d--flex fd--c">
                                    {order.proforma_url !== null && (
                                        <>
                                            <a href={order.proforma_url}
                                               className="btn btn--xs btn--text primary p--0 text--right"
                                               target="_blank"><i
                                                className="fad fa-download mr--xs"></i>Predfaktúra</a>
                                            <span
                                                className={"text--xs text--right"}><i>{moment(order?.proforma_updated_at).format('DD.MM.Y')}</i></span>
                                        </>
                                    )}
                                    {order.invoice_url !== null && (
                                        <>
                                            <a href={order.invoice_url}
                                               className="btn btn--xs btn--text primary p--0 mt--xs text--right"
                                               target="_blank"><i
                                                className="fad fa-download mr--xs"></i>Vyúčtovacia faktúra ku platbe</a>
                                            <span
                                                className={"text--xs text--right"}><i>{moment(order?.invoice_updated_at).format('DD.MM.Y')}</i></span>
                                        </>
                                    )}
                                    {order.credit_note_url !== null && (
                                        <>
                                            <a href={order.credit_note_url}
                                               className={'btn btn--xs btn--text primary p--0 mt--xs text--right'}
                                               target="_blank"><i
                                                className="fad fa-download mr--xs"></i>Dobropis</a>
                                            <span
                                                className={"text--xs text--right"}><i>{moment(order?.credit_note_updated_at).format('DD.MM.Y')}</i></span>
                                        </>
                                    )}
                                    {order.reconciliation_invoice_url !== null && (
                                        <>
                                            <a href={order.reconciliation_invoice_url}
                                               className={'btn btn--xs btn--text primary p--0 mt--xs mb--xs'}
                                               target="_blank"><i
                                                className="fad fa-download mr--xs"></i>Vyúčtovacia faktúra ku službe</a>
                                            <span
                                                className={"text--xs text--right"}><i>{moment(order?.reconciliation_invoice_updated_at).format('DD.MM.Y')}</i></span>
                                        </>
                                    )}
                                    {order.subtype_credit_note_urls !== null && (
                                        <>
                                            Zmeny v objednávke
                                            {order.subtype_credit_note_urls.map((credit_note, i) => (
                                                <div key={i}>
                                                    <a href={credit_note.url}
                                                       className={'btn btn--xs btn--text primary p--0 mt--xs'}
                                                       target="_blank"><i
                                                        className="fad fa-download mr--xs"></i>Dobropis</a>
                                                    <span
                                                        className={"text--xs text--right"}><i>{moment(credit_note?.updated_at).format('DD.MM.Y')}</i></span>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Žiadne objednávky</p>
                    )
                )
            }
        </div>
    )
}
